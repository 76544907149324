import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import SEO from '../../../components/SEO'
import Header from '../../../components/Header/Partners'
import Footer from '../../../components/Footer/Partners'
import { KpLinkLandingQuery } from '../../../../types/graphql-types'
import LayoutPartners from '../../../components/Layout/Partners/LayoutPartners'
interface Props {
  data: KpLinkLandingQuery
}

export const Hero = styled.section`
  @media (min-width: 1280px) {
    height: 480px;
  }

  h1 {
    ${tw`leading-none mb-8`}
  }
`
const HeroBar = styled.section`
  ${tw`py-3 bg-kp-blue text-white text-center`}
  h2 {
    ${tw`text-3xl`}
  }
  p {
    ${tw`mb-0 font-semibold`}
  }
`

const FeaturedContent = styled.section`
  h2,
  p {
    ${tw`lg:text-center`}
  }
  h2 {
    ${tw`mb-8`}
  }
  ul {
    ${tw`list-disc ml-4 lg:ml-16 xl:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col lg:grid-rows-2 lg:gap-x-8 xl:w-11/12`}

    li {
      ${tw`font-semibold`}
    }
  }
`

const ItemDescription = styled.div`
  ${tw`w-full block xl:mb-12`}

  :nth-of-type(2),
  :nth-of-type(3) {
    ${tw`lg:mt-16 xl:mt-32`}
  }

  h3 {
    ${tw`text-2xl`}
  }
  p {
    ${tw`mb-8 xl:mb-0`}
  }
`

const TextWrapper = styled.div`
  h2 {
    ${tw`mb-8`}
  }
`
const DownloadInfo = styled.section`
  h2 {
    ${tw`mb-8`}
  }

  a,
  a:link,
  a:hover,
  a:active,
  a:focus {
    ${tw`text-kp-blue`}
  }

  ol {
    ${tw`list-decimal ml-4`}
  }

  li {
    ${tw`float-left`}

    img {
      ${tw`py-4 mr-4`}
    }
  }
`

export const query = graphql`
  query KpLinkLanding {
    contentfulPage(externalName: { eq: "KP Link" }) {
      title
      externalName
      slug
      seoTitle
      seoDescription
      noindex
      gtmPageType
      references {
        ... on ContentfulMedia {
          id
          name
          externalName
          altText
          media {
            file {
              url
            }
          }
        }
        ...hero
        ... on ContentfulText {
          id
          name
          externalName
          longSimpleText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFeatureContent {
          id
          name
          externalName
          references {
            ... on ContentfulText {
              id
              name
              externalName
              shortSimpleText
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
              richText {
                raw
                references {
                  ... on ContentfulMedia {
                    __typename
                    id
                    altText
                    name
                    externalName
                    media {
                      file {
                        url
                      }
                    }
                    link {
                      name
                      externalName
                      type
                      ctaLink
                      openInNewWindow
                    }
                  }
                  ... on ContentfulGatsbyComponent {
                    __typename
                    contentful_id
                    name
                    externalName
                    id
                    references {
                      ... on ContentfulText {
                        id
                        name
                        shortSimpleText
                        longSimpleText {
                          childMarkdownRemark {
                            html
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ContentfulMedia {
              id
              name
              externalName
              altText
              media {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulList {
              id
              name
              externalName
              items {
                ... on ContentfulText {
                  id
                  name
                  shortSimpleText
                  longSimpleText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const KPLinkLandingPage = ({ data }: Props): JSX.Element => {
  const content = data.contentfulPage
  return (
    <LayoutPartners>
      <SEO
        title={content?.seoTitle as string}
        description={content?.seoDescription as string}
        noIndex={content?.noindex as boolean}
      />
      <Header
        logo={content?.references?.map(logo => {
          if (logo?.externalName === 'Brand Logo') {
            return (
              <img
                key={logo.id}
                className="h-7 sm:h-10"
                src={logo.media.file.url}
                alt={logo.altText}
              />
            )
          }
        })}
      />
      <main id="main">
        {content?.references?.map(mainContent => {
          if (mainContent?.externalName === 'KP Link Hero') {
            return (
              <Hero
                key={mainContent.id}
                className={`gradient-2-gray-1 md:h-360 mx-auto max-w-1920`}
              >
                <div className="flex h-full flex-wrap content-center">
                  <div
                    className={`container h-full grid-cols-12 pt-6 text-left md:grid md:pt-0`}
                  >
                    {mainContent?.references?.map(
                      (heroCopy: {
                        externalName: string
                        id: React.Key | null | undefined
                        longSimpleText: { childMarkdownRemark: { html: any } }
                      }) => {
                        if (heroCopy.externalName === 'Hero Copy') {
                          return (
                            <div
                              key={heroCopy.id}
                              className="place-self-center md:col-span-7 lg:col-span-6 xl:col-span-4"
                              dangerouslySetInnerHTML={{
                                __html:
                                  heroCopy?.longSimpleText?.childMarkdownRemark
                                    ?.html,
                              }}
                            ></div>
                          )
                        }
                      }
                    )}
                    {mainContent.references?.map(
                      (heroImage: {
                        externalName: string
                        id: React.Key | null | undefined
                        media: { file: { url: string | undefined } }
                      }) => {
                        if (heroImage.externalName === 'Hero Large') {
                          return (
                            <img
                              key={heroImage.id}
                              className="mx-auto place-self-end md:col-span-5 lg:col-span-4 lg:col-start-7"
                              alt=""
                              src={heroImage.media.file.url}
                            />
                          )
                        }
                      }
                    )}
                  </div>
                </div>
              </Hero>
            )
          }
          if (mainContent?.externalName === 'Hero Bar') {
            return (
              <HeroBar key={mainContent.id}>
                <div className="container">
                  <div
                    className="w-full md:text-center lg:mx-auto lg:w-10/12"
                    dangerouslySetInnerHTML={{
                      __html: mainContent.longSimpleText?.childMarkdownRemark
                        ?.html as any,
                    }}
                  ></div>
                </div>
              </HeroBar>
            )
          }
          if (mainContent?.externalName === 'Feature Content 1') {
            return (
              <FeaturedContent
                key={mainContent.id}
                className="content-section-py xl:w-2/5"
              >
                <div className="container mb-8">
                  {mainContent.references.map(
                    (feature: {
                      externalName: string
                      id: React.Key | null | undefined
                      shortSimpleText:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                      longSimpleText: { childMarkdownRemark: { html: any } }
                    }) => {
                      if (feature.externalName === 'Feature Content Copy') {
                        return (
                          <React.Fragment key={feature.id}>
                            <h2>{feature.shortSimpleText}</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: feature.longSimpleText
                                  ?.childMarkdownRemark?.html as any,
                              }}
                            ></div>
                          </React.Fragment>
                        )
                      }
                    }
                  )}
                </div>
              </FeaturedContent>
            )
          }

          if (mainContent?.externalName === 'Feature Content 2') {
            return (
              <FeaturedContent
                key={mainContent.id}
                className="content-section-full"
              >
                <div className="container mb-8 xl:w-2/5">
                  {mainContent.references.map(
                    (feature: {
                      externalName: string
                      id: React.Key | null | undefined
                      shortSimpleText:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                      longSimpleText: { childMarkdownRemark: { html: any } }
                    }) => {
                      if (feature.externalName === 'Feature Content Copy') {
                        return (
                          <React.Fragment key={feature.id}>
                            <h2>{feature.shortSimpleText}</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: feature.longSimpleText
                                  ?.childMarkdownRemark?.html as any,
                              }}
                            ></div>
                          </React.Fragment>
                        )
                      }
                    }
                  )}
                </div>
              </FeaturedContent>
            )
          }
          if (mainContent?.externalName === 'Exclusive Features') {
            return (
              <section className="content-section-py" key={mainContent.id}>
                {mainContent.references.map(
                  (copy: {
                    externalName: string
                    id: React.Key | null | undefined
                    longSimpleText: { childMarkdownRemark: { html: any } }
                  }) => {
                    if (copy.externalName === 'Feature Content Copy') {
                      return (
                        <TextWrapper
                          key={copy.id}
                          className="w-full md:text-center lg:mx-auto lg:w-10/12"
                          dangerouslySetInnerHTML={{
                            __html: copy.longSimpleText?.childMarkdownRemark
                              ?.html as any,
                          }}
                        ></TextWrapper>
                      )
                    }
                  }
                )}
                <div className="grid-cols-12 lg:mt-24 lg:grid">
                  {mainContent.references.map(
                    (media: {
                      externalName: string
                      id: React.Key | null | undefined
                      altText: string | undefined
                      media: { file: { url: string | undefined } }
                    }) => {
                      if (media.externalName === 'Features Image') {
                        return (
                          <div
                            key={media.id}
                            className="col-span-4 col-start-5 row-span-4 row-start-1"
                          >
                            <img
                              key={media.id}
                              alt={media.altText}
                              src={media.media.file.url}
                              className="-top-16 mx-auto lg:relative"
                            />
                          </div>
                        )
                      }
                    }
                  )}
                  {mainContent.references.map(
                    (copy: {
                      externalName: string
                      id: React.Key | null | undefined
                      items: any[]
                    }) => {
                      if (copy.externalName === 'Features') {
                        return (
                          <React.Fragment key={copy.id}>
                            {copy.items.map((list: any) => (
                              <ItemDescription
                                key={list.name}
                                className="col-span-4"
                              >
                                <h3>{list.shortSimpleText}</h3>
                                <div
                                  className="block w-full"
                                  dangerouslySetInnerHTML={{
                                    __html: list.longSimpleText
                                      ?.childMarkdownRemark?.html as any,
                                  }}
                                ></div>
                              </ItemDescription>
                            ))}
                          </React.Fragment>
                        )
                      }
                    }
                  )}
                </div>
                {mainContent.references.map((copy: { name: string }) => {
                  if (copy.externalName === 'Feature Content Disclaimer') {
                    return (
                      <div
                        key={copy.id}
                        className="block w-full"
                        dangerouslySetInnerHTML={{
                          __html: copy.longSimpleText?.childMarkdownRemark
                            ?.html as any,
                        }}
                      ></div>
                    )
                  }
                })}
              </section>
            )
          }
          if (mainContent?.externalName === 'Feature Content 4') {
            return (
              <DownloadInfo
                key={mainContent.id}
                className="content-section-full"
              >
                <div className="container mb-8 grid grid-cols-12">
                  {mainContent.references.map(feature => {
                    if (feature.externalName === 'Feature Content Copy') {
                      return (
                        <div
                          key={feature.id}
                          className="col-span-12 xl:col-span-7"
                        >
                          <h2 className="text-left">
                            {feature.shortSimpleText}
                          </h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: feature.longSimpleText
                                ?.childMarkdownRemark?.html as any,
                            }}
                          ></div>
                        </div>
                      )
                    }
                  })}
                </div>
              </DownloadInfo>
            )
          }
        })}
      </main>
      <Footer color="bg-kp-blue" data={data} />
    </LayoutPartners>
  )
}

export default KPLinkLandingPage
